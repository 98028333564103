:root {
  --color-chevron-bg: #3d7073;
  --color-font-black: #000;
  --color-font-white: #fff;
  --color-bg-white: #fff;
  --color-bg-grey: #dfdddd;
  --color-transparent: transparent;
  --color-page-bg-grey: #f2f2f2;
}

/* Customize the styles for the Menu component */
.ant-menu-item {
  font-weight: normal;
}

/* Change color of Chapter when selected and if there are no indicators inside */
.ant-menu-item
  .ant-menu-item-selected
  .ant-menu-item-only-child
  span.ant-menu-title-content {
  color: var(--color-font-black);
}

/* Change color of chapter when selected */
.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  div.ant-menu-submenu-title
  span.ant-menu-title-content {
  color: var(--color-font-black);
}

/* Change color of text when selected indicator */
.ant-menu-item.ant-menu-item-selected span.ant-menu-title-content {
  color: var(--color-font-black);
}

/* Change color of text when selected chapter without a indicator */
.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child
  span.ant-menu-title-content {
  color: var(--color-font-black);
}

/* Change color of icon when selected */
.ant-menu-item.ant-menu-item-selected
  span.anticon.anticon-line-chart.ant-menu-item-icon {
  color: var(--color-font-black);
}

/* Change color of chevron when selected */
.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open.ant-menu-submenu-selected
  div.ant-menu-submenu-title
  i.ant-menu-submenu-arrow {
  color: var(--color-font-black);
}

.ant-tree-node-content-wrapper-normal.ant-tree-node-selected {
  background: var(--color-bg-grey) !important;
}

.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-open.ant-tree-node-selected {
  margin-left: 1rem;
}

.ant-tree-node-content-wrapper {
  margin-bottom: 2rem;
}

.ant-tree-treenode.ant-tree-treenode-switcher-close
  span.ant-tree-switcher.ant-tree-switcher-noop.custom {
  width: 24px;
  height: 24px;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' fill='%2300000' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='50' cy='50' r='20' /%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
}

.ant-tree-list-holder-inner
  div.ant-tree-treenode.ant-tree-treenode-switcher-open.ant-tree-treenode-selected {
  margin-top: 1rem;
}

.ant-tree-list-holder-inner
  div.ant-tree-treenode.ant-tree-treenode-switcher-close {
  margin-top: 1rem;
}

.ant-tree-node-content-wrapper-normal.ant-tree-node-selected
  span.ant-tree-iconEle.ant-tree-icon__customize {
  display: block;
  margin-right: 0.5rem;
}

.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal.ant-tree-node-selected {
  display: flex;
}

.ant-tree-treenode-switcher-close
  span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal {
  display: flex;
}

.ant-tree-node-content-wrapper-normal
  span.ant-tree-iconEle.ant-tree-icon__customize {
  display: block;

  margin-right: 0.5rem;
}

.ant-tree-list-holder-inner
  div.ant-tree-treenode.ant-tree-treenode-switcher-open {
  margin-top: 1rem;
}

.ant-tree-treenode-switcher-open
  span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-open {
  margin-left: 1rem;
}

/* This adds color to the chapter if it has indicators */
.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-open.ant-tree-node-selected {
  background: var(--color-transparent);
}

/* Change color of chapter when selected on mobile */
.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-selected
  div.ant-menu-submenu-title
  span.ant-menu-title-content {
  color: var(--color-font-black);
}

/* Change color of chevron when selected, on mobile */
.ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-selected
  div.ant-menu-submenu-title
  i.ant-menu-submenu-arrow {
  color: var(--color-font-black);
}

.ant-layout-sider.ant-layout-sider-light div.ant-layout-sider-children {
  overflow: auto;
}

.ant-tree-switcher.ant-tree-switcher_open {
  background: var(--color-chevron-bg);
  border-radius: 50%;
  color: var(--color-font-white);
  height: fit-content;
}
